import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import ThankYouSection from "../../components/thank-you-section";
import { graphql } from "gatsby";
import Layout from "@layout";
import SEO from "@components/seo";
import { normalizedData } from "@utils/functions";

const Blog4 = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO title='Blog' pathname='/' />
            <Container>
                <ThankYouSection
                    image='https://reliance-cdn.dev.quanticdynamics.cloud/img/news/We%20are%20proud%20to%20announce%20that%20%20Reliance%20Insurance%20Limited%20secured%201st%20Position%20for%20Best%20Presented%20Annual%20Report%202020%20-%20Insurance%20Sector%20in%20the%2021st%20ICAB%20National%20Award!.jpg'
                    name='blog3'
                    designation='image'
                    title='“When you need Authentic & Comprehensive Information on Insurance plus Real Experience, Real Insight, Personalized Services, and Pragmatic Solutions……That’s the time, you need RELIANCE INSURANCE LIMITED!”'
                    disc1='“When you need Authentic & Comprehensive Information on Insurance plus Real Experience, Real Insight, Personalized Services, and Pragmatic Solutions……That’s the time, you need RELIANCE INSURANCE LIMITED!”'
                />
            </Container>
        </Layout>
    );
};
Blog4.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query Blog4Query {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default Blog4;
